import Chart, { ChartConfiguration, ChartData } from 'chart.js';
import { RGB, StakeholderModelMapping } from './models';
import { DrawLegendIcon, rgba } from './shared';

interface StakeholderMappingScattergramProcessedDataPoint {
    interest: number;
    influence: number;
    impact: number;
    stakeholderIDs: Set<number>;
    stakeholderCount: number;
}

type StakeholderMappingScattergramProcessedData = StakeholderMappingScattergramProcessedDataPoint[][][];

interface StakeholderMappingScattergramOriginalData {
    interest: number;
    influence: number;
    impact: number;
    stakeholderCount: number;
}

interface StakeholderMappingScattergramPoint {
    x: number;
    y: number;
    r: number;
    originalData: StakeholderMappingScattergramOriginalData;
}

export class StakeholderMappingScattergramWidget {
    readonly chart: Chart;

    isLoadingMapping = false;

    onDataUpdated: (totalStakeholders: number, stakeholdersWithMapping: number) => void;

    readonly colours = this.createColours([
        [183, 192, 199], // Grey      - Not Set
        [214, 238, 245], // Pale Blue - Very Low
        [174, 221, 235], //           - Low
        [134, 205, 225], //           - Moderate
        [94, 188, 215],  //           - High
        [54, 172, 206],  // Dark Blue - Very High
    ]);

    readonly pointSizes = [
        4,
        6,
        8,
        10,
        12,
        14,
    ];

    readonly labels = ['', 'Very Low', 'Low', 'Moderate', 'High', 'Very High'];

    private readonly chartConfig: ChartConfiguration = {
        type: 'bubble',
        data: {
            datasets: [
                {
                    data: [],
                },
            ],
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false,
            },
            scales: {
                xAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: 'IMPACT',
                    },
                    ticks: {
                        beginAtZero: true,
                        stepSize: 1,
                        max: 6,
                        callback: (value) => this.labels[value],
                    },
                }],
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: 'INTEREST',
                    },
                    ticks: {
                        beginAtZero: true,
                        stepSize: 1,
                        max: 6,
                        callback: (value) => this.labels[value],
                    },
                }],
            },
            elements: {
                point: {
                    backgroundColor: (context) => {
                        const originalData = this.getOriginalData(
                            context.chart.data,
                            context.datasetIndex,
                            context.dataIndex
                        );

                        return originalData ? this.colours.backgroundColor[originalData.influence] : undefined;
                    },
                    borderColor: (context) => {
                        const originalData = this.getOriginalData(
                            context.chart.data,
                            context.datasetIndex,
                            context.dataIndex
                        );

                        return originalData ? this.colours.borderColor[originalData.influence] : undefined;
                    },
                },
            },
            tooltips: {
                callbacks: {
                    label: (tooltipItem, data) => {
                        const originalData = this.getOriginalData(
                            data,
                            tooltipItem.datasetIndex,
                            tooltipItem.index
                        );

                        return [
                            'Influence: ' + this.labels[originalData.influence],
                            'Interest: ' + this.labels[originalData.interest],
                            'Impact: ' + this.labels[originalData.impact],
                            'Number of Stakeholders: ' + originalData.stakeholderCount,
                        ];
                    },
                },
            },
        },
    }

    // Used to calculate the thresholds for scaling the points
    private readonly thresholdCalculationChart = new Chart(document.createElement('canvas'), {
        type: 'line',
        data: {
            datasets: [
                {
                    data: [],
                },
            ],
        },
        options: {
            scales: {
                yAxes: [{
                    ticks: {
                        min: 1,
                        maxTicksLimit: 5,
                    },
                }],
            },
        },
    });

    private _mappingData: StakeholderModelMapping[];

    constructor(
        chart: JQuery<HTMLCanvasElement>,
        private readonly sizeLegend: JQuery,
        customChartConfig?: ChartConfiguration,
    ) {
        this.chart = new Chart(
            chart,
            $.extend(true, {}, this.chartConfig, customChartConfig)
        );
    }

    public get mappingData(): StakeholderModelMapping[] {
        return this._mappingData;
    }

    public set mappingData(value: StakeholderModelMapping[]) {
        this._mappingData = value;
        this.updateData();
    }

    public drawInfluenceLegendIcons(influenceLegendIcons: JQuery<HTMLCanvasElement>): void {
        const colours = this.colours;
        const size = 12;

        influenceLegendIcons.each(function () {
            const index = $(this).data('index');
            const colour = colours.backgroundColor[index];

            DrawLegendIcon(this, colour, size);
        });
    }

    private updateData(): void {
        // Do nothing if the data is still loading
        if (this.isLoadingMapping) {
            return;
        }

        // x = Impact, y = Interest, colour = Influence, radius = number of stakeholders

        if (!this.mappingData || this.mappingData.length === 0) {
            this.setChartData(this.chart, []);

            if (this.onDataUpdated) {
                this.onDataUpdated(0, 0);
            }

            return;
        }

        const totalStakeholders = this.mappingData.length;
        let stakeholdersWithMapping = 0;

        // [influence - 1][impact - 1][interest - 1] = {
        //      influence: <influence>,
        //      impact: <impact>,
        //      interest: <interest>,
        //      stakeholderIDs: <Set of Stakeholder IDs>
        // }

        // [0] = Very Low, [4] = Very High
        const processedData: StakeholderMappingScattergramProcessedData = [];
        for (let influence = 0; influence < 5; influence++) {
            processedData[influence] = [];

            for (let impact = 0; impact < 5; impact++) {
                processedData[influence][impact] = [];

                for (let interest = 0; interest < 5; interest++) {
                    processedData[influence][impact][interest] = {
                        influence: influence + 1,
                        impact: impact + 1,
                        interest: interest + 1,
                        stakeholderIDs: new Set(),
                        stakeholderCount: 0,
                    };
                }
            }
        }

        const hasValue = (value: number) => value > 0 && value <= 5;

        this.mappingData.forEach((stakeholder) => {
            if (hasValue(stakeholder.Influence) && hasValue(stakeholder.Impact) && hasValue(stakeholder.Interest)) {
                stakeholdersWithMapping++;

                processedData
                [stakeholder.Influence - 1]
                [stakeholder.Impact - 1]
                [stakeholder.Interest - 1]
                    .stakeholderIDs.add(stakeholder.StakeholderID);
            }
        });


        if (stakeholdersWithMapping === 0) {
            if (this.onDataUpdated) {
                this.onDataUpdated(totalStakeholders, stakeholdersWithMapping);
            }

            this.setChartData(this.chart, []);

            return;
        }

        // Calculate the size of each Set of Stakeholder IDs and keep track of them in an array
        const stakeholderCounts: number[] = [];
        for (let influence = 0; influence < 5; influence++) {
            for (let impact = 0; impact < 5; impact++) {
                for (let interest = 0; interest < 5; interest++) {
                    const stakeholderCount = processedData[influence][impact][interest].stakeholderIDs.size;
                    processedData[influence][impact][interest].stakeholderCount = stakeholderCount;

                    if (stakeholderCount > 0) {
                        stakeholderCounts.push(stakeholderCount);
                    }
                }
            }
        }

        // Calculate the thresholds used to scale the points
        stakeholderCounts.sort((a, b) => a - b);
        this.thresholdCalculationChart.data.datasets[0].data = stakeholderCounts;
        this.thresholdCalculationChart.update();

        interface ChartWithTicksAsNumbersScale {
            scales: {
                [key: string]: {
                    ticksAsNumbers: number[];
                };
            };
        }

        const pointSizeThresholds = (this.thresholdCalculationChart as unknown as ChartWithTicksAsNumbersScale)
            .scales['y-axis-0']
            .ticksAsNumbers
            .slice()
            .reverse();

        const chartPointSizes = this.pointSizes.slice(0, pointSizeThresholds.length);

        // Map the processed data to chart points
        const chartData: StakeholderMappingScattergramPoint[] = [];
        const pointsByCoordinate: StakeholderMappingScattergramPoint[][][] = []; // [x][y] = [point1, point2, ...]

        for (let influence = 0; influence < 5; influence++) {
            for (let impact = 0; impact < 5; impact++) {
                for (let interest = 0; interest < 5; interest++) {
                    const mappingValues = processedData[influence][impact][interest];
                    if (mappingValues.stakeholderCount > 0) {
                        const sizeIndex = pointSizeThresholds.findIndex((threshold) => mappingValues.stakeholderCount <= threshold);
                        const point: StakeholderMappingScattergramPoint = {
                            x: mappingValues.impact,
                            y: mappingValues.interest,
                            r: chartPointSizes[sizeIndex],
                            originalData: {
                                impact: mappingValues.impact,
                                interest: mappingValues.interest,
                                influence: mappingValues.influence,
                                stakeholderCount: mappingValues.stakeholderCount,
                            },
                        };

                        chartData.push(point);

                        if (!pointsByCoordinate[point.x]) {
                            pointsByCoordinate[point.x] = [];
                        }

                        if (!pointsByCoordinate[point.x][point.y]) {
                            pointsByCoordinate[point.x][point.y] = [];
                        }

                        pointsByCoordinate[point.x][point.y].push(point);
                    }
                }
            }
        }

        // Spread out points that have the same x/y coordinates
        const spacingFactor = 0.25;
        for (let x = 1; x <= 5; x++) {
            for (let y = 1; y <= 5; y++) {
                if (pointsByCoordinate[x] && pointsByCoordinate[x][y] && pointsByCoordinate[x][y].length > 1) {
                    const points = pointsByCoordinate[x][y];
                    points.sort((pointA, pointB) => pointA.originalData.influence - pointB.originalData.influence);

                    switch (points.length) {
                        case 2:
                            //       |
                            //       |
                            //---1---|---2---
                            //       |
                            //       |

                            points[0].x -= spacingFactor;
                            points[1].x += spacingFactor;
                            break;

                        case 3:
                            //       |
                            //       |
                            //---1---2---3---
                            //       |
                            //       |

                            points[0].x -= spacingFactor;
                            points[2].x += spacingFactor;
                            break;

                        case 4:
                            //   1   |   3
                            //       |
                            //-------|-------
                            //       |
                            //   2   |   4

                            points[0].x -= spacingFactor;
                            points[0].y += spacingFactor;

                            points[1].x -= spacingFactor;
                            points[1].y -= spacingFactor;

                            points[2].x += spacingFactor;
                            points[2].y += spacingFactor;

                            points[3].x += spacingFactor;
                            points[3].y -= spacingFactor;

                            break;

                        case 5:
                            //   1   |   4
                            //       |
                            //-------3-------
                            //       |
                            //   2   |   5

                            points[0].x -= spacingFactor;
                            points[0].y += spacingFactor;

                            points[1].x -= spacingFactor;
                            points[1].y -= spacingFactor;

                            points[2].x += spacingFactor;
                            points[2].y += spacingFactor;

                            points[4].x += spacingFactor;
                            points[4].y -= spacingFactor;

                            break;
                    }
                }
            }
        }

        this.setChartData(this.chart, chartData);

        this.updateSizeLegend(chartData.length > 0, chartPointSizes, pointSizeThresholds);

        if (this.onDataUpdated) {
            this.onDataUpdated(totalStakeholders, stakeholdersWithMapping);
        }
    }

    private updateSizeLegend(hasData: boolean, chartPointSizes: number[], pointSizeThresholds: number[]) {
        if (hasData) {
            this.sizeLegend.show();

            const largestPointSize = chartPointSizes[chartPointSizes.length - 1];
            const colours = this.colours;

            this.sizeLegend.find('.legend-item').each(function () {
                const index = Number($(this).data('index'));

                if (index < chartPointSizes.length) {
                    $(this).show();

                    const icon = $(this).find('.legendIcon')[0] as HTMLCanvasElement;
                    const radius = chartPointSizes[index];
                    const diameter = radius * 2;

                    icon.width = diameter;
                    icon.height = diameter;

                    DrawLegendIcon(icon, colours.backgroundColor[0], diameter);

                    const min = index == 0 ? 1 : pointSizeThresholds[index - 1] + 1;
                    const max = pointSizeThresholds[index];

                    if (min === max) {
                        $(this).find('.legend-item-label').text(min);
                    } else {
                        $(this).find('.legend-item-label').text(`${min} - ${max}`);
                    }

                    // Ensure all legend items are the same height
                    $(this).css('min-height', `${largestPointSize * 2}px`);
                } else {
                    $(this).hide();
                }
            });
        } else {
            this.sizeLegend.hide();
        }
    }

    private setChartData(chart: Chart, data: StakeholderMappingScattergramPoint[]): void {
        chart.data.datasets[0].data = data;

        const $canvas = $(chart.canvas);
        const $noData = $canvas.siblings('.no-data');

        if (data.length === 0) {
            $canvas.hide();
            $noData.show();
        } else {
            $canvas.show();
            $noData.hide();
        }

        chart.update();
    }

    private getOriginalData(
        data: ChartData,
        datasetIndex: number,
        dataIndex: number
    ): StakeholderMappingScattergramOriginalData {
        const point = data.datasets[datasetIndex].data[dataIndex];

        return point ? (point as StakeholderMappingScattergramPoint).originalData : undefined;
    }

    private createColours(colours: RGB[]) {
        return {
            backgroundColor: colours.map((colour) => rgba(colour, 1)),
            borderColor: colours.map((colour) => rgba(colour, 1)),
        };
    }
}
