import Chart, { ChartConfiguration } from 'chart.js';
import { InteractionSentimentData } from './models';
import { DrawLegendIcon } from './shared';

export class InteractionSentimentSummaryWidget {
    readonly chart: Chart;

    isLoadingSentiment = false;

    onDataUpdated: (totalSentimentCount: number, noSentimentCount: number) => void;

    private readonly colours = [
        '#d6eef5',
        '#aeddeb',
        '#86cde1',
        '#5ebcd7',
        '#36acce',
    ];

    private readonly sentimentLabels = [
        'Negative',
        'Somewhat Negative',
        'Neutral',
        'Somewhat Positive',
        'Positive',
    ];

    private readonly sentimentTypes = [
        'Negative',
        'Somewhat Negative',
        'Neutral',
        'Somewhat Positive',
        'Positive',
    ];

    private readonly sentimentTypeToIndex = new Map(
        this.sentimentTypes.map((label, index) => [label, index])
    );

    private readonly chartConfig: ChartConfiguration = {
        type: 'pie',
        data: {
            labels: this.sentimentLabels,
            datasets: [
                {
                    backgroundColor: this.colours,
                    borderColor: [
                        '#fff',
                        '#fff',
                        '#fff',
                        '#fff',
                        '#fff',
                    ],
                    borderWidth: 2
                },
            ],
        },
        options: {
            legend: {
                display: false,
            },
        },
    };

    private _sentimentData: InteractionSentimentData;

    constructor(
        chart: JQuery<HTMLCanvasElement>,
        customChartConfig?: ChartConfiguration,
    ) {
        this.chart = new Chart(
            chart,
            $.extend(true, {}, this.chartConfig, customChartConfig)
        );
    }

    public get sentimentData(): InteractionSentimentData {
        return this._sentimentData;
    }

    public set sentimentData(value: InteractionSentimentData) {
        this._sentimentData = value;
        this.updateData();
    }

    public drawLegendIcons(sentimentLegendIcons: JQuery<HTMLCanvasElement>): void {
        const colours = this.colours;
        const size = 12;

        sentimentLegendIcons.each(function () {
            const index = $(this).data('index');
            const colour = colours[index];

            DrawLegendIcon(this, colour, size);
        });
    }

    private updateData() {
        // Do nothing if the data is still loading
        if (this.isLoadingSentiment) {
            return;
        }

        let totalSentimentCount = 0;
        let noSentimentCount = 0;
        const sentimentCounts = [0, 0, 0, 0, 0];

        this.sentimentData.Interactions.forEach(interaction => {
            if (interaction.SentimentType && typeof interaction.SentimentScore === 'number') {
                const index = this.sentimentTypeToIndex.get(interaction.SentimentType);

                sentimentCounts[index]++;

                totalSentimentCount++;
            } else {
                noSentimentCount++;
            }
        });

        this.chart.data.datasets[0].data = sentimentCounts;
        this.chart.update();

        if (this.onDataUpdated) {
            this.onDataUpdated(totalSentimentCount, noSentimentCount);
        }
    }
}