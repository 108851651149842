import { ChartConfiguration } from 'chart.js';
import { SelectListItem, StakeholderModelMapping } from './models';
import { StakeholderMappingPositionCriticalityPoint, StakeholderMappingPositionCriticalityWidget } from './stakeholder-mapping-position-criticality';

interface StakeholderMappingPositionCriticalityChangePoint extends StakeholderMappingPositionCriticalityPoint {
    previous: number;
    current: number;
}

export class StakeholderMappingPositionCriticalityChangeWidget extends StakeholderMappingPositionCriticalityWidget {
    isLoadingPreviousMapping = false;

    private _previousMappingData: StakeholderModelMapping[];

    constructor(
        chart: JQuery<HTMLCanvasElement>,
        readonly positions: SelectListItem[],
        readonly criticalities: SelectListItem[],
        customChartConfig?: ChartConfiguration,
    ) {
        super(
            chart,
            positions,
            criticalities,
            $.extend<ChartConfiguration, ChartConfiguration>(
                true,
                {
                    data: {
                        datasets: [
                            {
                                datalabels: {
                                    formatter: (value: StakeholderMappingPositionCriticalityChangePoint, _ctx) => {
                                        if (value.previous === value.current) {
                                            return `${value.current}`;
                                        }

                                        return `${value.previous} > ${value.current}`;
                                    },
                                },
                            },
                        ],
                    },
                    options: {
                        tooltips: {
                            callbacks: {
                                label: (tooltipItem, data) => {
                                    const point =
                                        data.datasets[0].data[tooltipItem.index] as StakeholderMappingPositionCriticalityChangePoint;

                                    return [
                                        'Position: ' + this.positionLabel(this.reverseShortPositionLabels.get(point.y as string)),
                                        'Criticality: ' + this.criticalityLabel(this.reverseShortCriticalityLabels.get(point.x as string)),
                                        'Previous number of Stakeholders: ' + point.previous,
                                        'Current number of Stakeholders: ' + point.current,
                                    ];
                                },
                            },
                        },
                    },
                },
                customChartConfig,
            )
        );
    }

    public get previousMappingData(): StakeholderModelMapping[] {
        return this._previousMappingData;
    }

    public set previousMappingData(value: StakeholderModelMapping[]) {
        this._previousMappingData = value;
        this.updateData();
    }

    protected updateData(): void {
        // Do nothing if the data is still loading
        if (this.isLoadingMapping || this.isLoadingPreviousMapping) {
            return;
        }

        if (!this.mappingData || !this.previousMappingData) {
            this.setChartData(this.chart, []);
            return;
        }

        const { chartData: currentChartData } = this.processMappingData(this.mappingData);

        const { chartData: previousChartData } = this.processMappingData(this.previousMappingData);

        const chartData: StakeholderMappingPositionCriticalityChangePoint[] = [];

        for (let i = 0; i < currentChartData.length; i++) {
            const currentData = currentChartData[i];
            const previousData = previousChartData[i];

            chartData[i] = {
                x: currentData.x,
                y: currentData.y,
                v: undefined,
                current: currentData.v,
                previous: previousData.v,
            };
        }

        this.setChartData(this.chart, chartData);
    }
}