import Chart from 'chart.js';
import 'chartjs-chart-matrix';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { MatrixChart, RGB, SelectListItem, StakeholderModelMapping } from './models';
import { rgba } from './shared';

export interface StakeholderMappingPositionCriticalityPoint {
    x: string;
    y: string;
    v: number;
}

export type StakeholderMappingPositionCriticalityChartData = Map<number, Map<number, number[]>>;

export class StakeholderMappingPositionCriticalityWidget {
    readonly chart: Chart;

    isLoadingMapping = false;

    readonly shortPositionLabels = new Map([
        [null, 'NS'],
        [-2, '-2'],
        [-1, '-1'],
        [0, '0'],
        [1, '1'],
        [2, '2'],
    ]);

    readonly reverseShortPositionLabels = new Map(
        [...this.shortPositionLabels.entries()].map(entry => [entry[1], entry[0]])
    );

    readonly shortCriticalityLabels = new Map([
        [null, 'NS'],
        [1, 'L'],
        [2, 'M'],
        [3, 'H'],
    ]);

    readonly reverseShortCriticalityLabels = new Map(
        [...this.shortCriticalityLabels.entries()].map(entry => [entry[1], entry[0]])
    );

    readonly colours = this.createColours([
        [ // Criticality = Not Set
            null,
            [
                [null, [246, 246, 246]], // Light Grey - Position = Not Set
                [-2, [212, 212, 212]],   //                         Very Negative
                [-1, [195, 195, 195]],   //                         Negative
                [0, [179, 179, 179]],    //                         Neutral
                [1, [161, 161, 161]],    //                         Positive
                [2, [144, 144, 144]],    // Dark Grey  - Position = Very High
            ],
        ],
        [ // Criticality = Low (Supporting engagement)
            1,
            [
                [null, [212, 212, 212]], // Grey   - Position = Not Set
                [-2, [255, 191, 191]],   // Red    -            Very Negative
                [-1, [254, 216, 192]],   //                     Negative
                [0, [255, 239, 191]],    // Orange -            Neutral
                [1, [236, 254, 202]],    //                     Positive
                [2, [228, 254, 228]],    // Green  - Position = Very Postive
            ],
        ],
        [ // Criticality = Medium (Important to engage)
            2,
            [
                [null, [179, 179, 179]], // Grey   - Position = Not Set
                [-2, [255, 102, 102]],   // Red    -            Very Negative
                [-1, [253, 161, 104]],   //                     Negative
                [0, [255, 217, 102]],    // Orange -            Neutral
                [1, [198, 254, 168]],    //                     Positive
                [2, [174, 228, 174]],    // Green  - Position = Very Postive
            ],
        ],
        [ // Criticality = High (Critical we engage)
            3,
            [
                [null, [144, 144, 144]], // Grey   - Position = Not Set
                [-2, [255, 0, 0]],       // Red    -            Very Negative
                [-1, [252, 98, 3]],      //                     Negative
                [0, [255, 192, 0]],      // Orange -            Neutral
                [1, [151, 253, 96]],     //                     Positive
                [2, [120, 210, 120]],    // Green  - Position = Very Postive
            ],
        ],
    ]);

    private readonly chartConfig: MatrixChart.Configuration = {
        type: 'matrix',
        plugins: [ChartDataLabels],
        data: {
            datasets: [{
                label: 'Position vs. Criticality',
                data: [],
                backgroundColor: (ctx) => {
                    const point = ctx.dataset.data[ctx.dataIndex] as StakeholderMappingPositionCriticalityPoint;

                    if (!point) {
                        return;
                    }

                    return this.colours.get(point.x).get(point.y);
                },
                width: (ctx) => {
                    const area = ctx.chart.chartArea;
                    return (area.right - area.left) / 4;
                },
                height: (ctx) => {
                    const area = ctx.chart.chartArea;
                    return (area.bottom - area.top) / 6;
                },
                datalabels: {
                    formatter: (value: StakeholderMappingPositionCriticalityPoint, _ctx) => value.v,
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                },
            }],
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false,
            },
            scales: {
                xAxes: [{
                    type: 'category',
                    labels: ['NS', 'L', 'M', 'H'],
                    scaleLabel: {
                        display: true,
                        labelString: 'CRITICALITY',
                    },
                    offset: true,
                }],
                yAxes: [{
                    type: 'category',
                    labels: ['2', '1', '0', '-1', '-2', 'NS'],
                    scaleLabel: {
                        display: true,
                        labelString: 'POSITION',
                    },
                    offset: true,
                }],
            },
            tooltips: {
                callbacks: {
                    title: () => '',
                    label: (tooltipItem, data) => {
                        const point = data.datasets[0].data[tooltipItem.index] as StakeholderMappingPositionCriticalityPoint;

                        return [
                            'Position: ' + this.positionLabel(this.reverseShortPositionLabels.get(point.y)),
                            'Criticality: ' + this.criticalityLabel(this.reverseShortCriticalityLabels.get(point.x)),
                            'Number of Stakeholders: ' + point.v,
                        ];
                    },
                },
            },
        },
    };

    private _mappingData: StakeholderModelMapping[];

    constructor(
        chart: JQuery<HTMLCanvasElement>,
        readonly positions: SelectListItem[],
        readonly criticalities: SelectListItem[],
        customChartConfig?: Chart.ChartConfiguration,
    ) {
        this.chart = new Chart(
            chart,
            $.extend(true, {}, this.chartConfig, customChartConfig)
        );
    }

    public get mappingData(): StakeholderModelMapping[] {
        return this._mappingData;
    }

    public set mappingData(value: StakeholderModelMapping[]) {
        this._mappingData = value;
        this.updateData();
    }

    protected updateData(): void {
        // Do nothing if the data is still loading
        if (this.isLoadingMapping) {
            return;
        }

        if (!this.mappingData) {
            this.setChartData(this.chart, []);
            return;
        }

        const { chartData } = this.processMappingData(this.mappingData);

        // Update the chart with the Position vs. Criticality counts
        this.setChartData(this.chart, chartData);
    }

    protected processMappingData(mappingData: StakeholderModelMapping[]) {
        // Calculate the number of Stakeholders at each level of Criticality (undefined, 1, 2, 3) and Position (undefined, -2 - +2)
        const positionCriticalityData = this.createEmptyChartData();

        mappingData.forEach((stakeholder) => {
            const positionsArray = positionCriticalityData
                .get(stakeholder.Criticality?.CriticalityID ?? null)
                .get(stakeholder.Position ?? null);

            positionsArray?.push(stakeholder.StakeholderID);
        });


        /*
         * Transform the data into a Chart.js dataset
         *
         * Map<
         *     number, // CriticalityID
         *     Map<
         *         number,  // Position
         *         number[] // Stakeholder IDs
         *     >
         * >
         *  =>
         * [
         *     {
         *         x: string, // Short Criticality Label (L, M, H)
         *         y: string, // Short Position Label (NS, -2, -1, 0, 1, 2)
         *         v: number, // Number of Stakeholders
         *     },
         *     ...
         * ]
         */
        const chartData: StakeholderMappingPositionCriticalityPoint[] =
            [...positionCriticalityData.entries()].flatMap((criticalityEntry) => {
                const [criticalityID, positionsMap] = criticalityEntry;

                return [...positionsMap.entries()].map((positionEntry) => {
                    const [position, positionArray] = positionEntry;

                    return {
                        x: this.shortCriticalityLabels.get(criticalityID),
                        y: this.shortPositionLabels.get(position),
                        v: positionArray.length,
                    };
                });
            });

        return { positionCriticalityData, chartData };
    }

    protected setChartData(chart: Chart, data: StakeholderMappingPositionCriticalityPoint[]) {
        chart.data.datasets[0].data = data;

        const $canvas = $(chart.canvas);
        const $noData = $canvas.siblings('.no-data');

        if (data.every(d => d.v === 0)) {
            $canvas.hide();
            $noData.show();
        } else {
            $canvas.show();
            $noData.hide();
        }

        chart.update();
    }

    private createEmptyChartData(): StakeholderMappingPositionCriticalityChartData {
        const data: StakeholderMappingPositionCriticalityChartData = new Map();

        for (let criticalityID = 0; criticalityID <= 3; criticalityID++) {
            const positionsMap = new Map();

            for (let position = -3; position <= 2; position++) {
                positionsMap.set(position !== -3 ? position : null, []);
            }

            data.set(criticalityID !== 0 ? criticalityID : null, positionsMap);
        }

        return data;
    }

    protected positionLabel(value: number) {
        return this.getLabel(value, this.positions);
    }

    protected criticalityLabel(criticalityID: number) {
        return this.getLabel(criticalityID, this.criticalities);
    }

    private getLabel(value: number, values: SelectListItem[]) {
        if (!values) {
            return;
        }

        return values.find((v) => v.Value == value)?.Text;
    }

    private createColours(
        colours: [
            number, // CriticalityID
            [
                number, // Position
                RGB // Colour
            ][]
        ][]
    ) {
        return new Map(
            colours.map((criticalityColourPair) => {
                const [criticalityID, positionColours] = criticalityColourPair;

                return [
                    this.shortCriticalityLabels.get(criticalityID),
                    new Map(
                        positionColours.map((positionColourPair) => {
                            const [position, positionColour] = positionColourPair;

                            return [
                                this.shortPositionLabels.get(position),
                                rgba(positionColour, 1)
                            ];
                        })
                    )
                ];
            })
        );
    }
}
