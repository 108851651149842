import Chart, { ChartConfiguration } from 'chart.js';
import { StakeholderModelMapping } from './models';
import { DrawLegendIcon } from './shared';

type StakeholderMappingChartData = [number, number, number, number, number];

export class StakeholderMappingWidget {
    readonly influenceChart: Chart;
    readonly interestChart: Chart;
    readonly impactChart: Chart;

    isLoadingMapping = false;

    private readonly labels = ['Very Low', 'Low', 'Moderate', 'High', 'Very High'];

    private readonly colours = [
        '#d6eef5',
        '#aeddeb',
        '#86cde1',
        '#5ebcd7',
        '#36acce',
    ];

    private readonly chartConfig: Chart.ChartConfiguration = {
        type: 'pie',
        data: {
            labels: this.labels,
            datasets: [{
                backgroundColor: this.colours,
                borderColor: [
                    '#fff',
                    '#fff',
                    '#fff',
                    '#fff',
                    '#fff',
                ],
                borderWidth: 2,
            }],
        },
        options: {
            legend: {
                display: false,
            },
            maintainAspectRatio: false,
        },
    };

    private _mappingData: StakeholderModelMapping[];

    private _influence: StakeholderMappingChartData;
    private _interest: StakeholderMappingChartData;
    private _impact: StakeholderMappingChartData;

    constructor(
        influenceChart: JQuery<HTMLCanvasElement>,
        interestChart: JQuery<HTMLCanvasElement>,
        impactChart: JQuery<HTMLCanvasElement>,
        customChartConfig?: ChartConfiguration,
    ) {
        this.influenceChart = new Chart(influenceChart, $.extend(true, {}, this.chartConfig, customChartConfig));
        this.interestChart = new Chart(interestChart, $.extend(true, {}, this.chartConfig, customChartConfig));
        this.impactChart = new Chart(impactChart, $.extend(true, {}, this.chartConfig, customChartConfig));
    }

    public get mappingData(): StakeholderModelMapping[] {
        return this._mappingData;
    }

    public set mappingData(value: StakeholderModelMapping[]) {
        this._mappingData = value;
        this.updateData();
    }

    public get influence(): StakeholderMappingChartData {
        return this._influence;
    }

    public get interest(): StakeholderMappingChartData {
        return this._interest;
    }

    public get impact(): StakeholderMappingChartData {
        return this._impact;
    }

    public drawLegendIcons(mappingLegendIcons: JQuery<HTMLCanvasElement>): void {
        const colours = this.colours;
        const size = 12;

        mappingLegendIcons.each(function () {
            const index = $(this).data('index');
            const colour = colours[index];

            DrawLegendIcon(this, colour, size);
        });
    }

    private updateData(): void {
        // Do nothing if the data is still loading
        if (this.isLoadingMapping) {
            return;
        }

        // Calculate the number of Stakeholders at each level of Influence, Interest and Impact
        // [0] is Very Low, [4] is Very High
        const influence: StakeholderMappingChartData = [0, 0, 0, 0, 0];
        const interest: StakeholderMappingChartData = [0, 0, 0, 0, 0];
        const impact: StakeholderMappingChartData = [0, 0, 0, 0, 0];

        this._influence = influence;
        this._interest = interest;
        this._impact = impact;

        this.mappingData.forEach((stakeholder) => {
            // Ignore 0 and invalid values
            if (stakeholder.Influence > 0 && stakeholder.Influence <= 5) {
                influence[stakeholder.Influence - 1]++;
            }

            if (stakeholder.Interest > 0 && stakeholder.Interest <= 5) {
                interest[stakeholder.Interest - 1]++;
            }

            if (stakeholder.Impact > 0 && stakeholder.Impact <= 5) {
                impact[stakeholder.Impact - 1]++;
            }
        });

        this.setChartData(this.influenceChart, influence);
        this.setChartData(this.interestChart, interest);
        this.setChartData(this.impactChart, impact);
    }

    private setChartData(
        chart: Chart,
        data: StakeholderMappingChartData | [],
    ): void {
        chart.data.datasets[0].data = data;

        chart.update();
    }
}
