import Chart, { ChartConfiguration } from 'chart.js';
import * as pattern from 'patternomaly';
import { RGB, ShapeType, StakeholderModelMapping } from './models';
import { DrawLegendIcon } from './shared';

type StakeholderMappingEngagementChartData = [number[], number[], number[], number[], number[], number[]];

class StakeholderMappingEngagementChartFullData {
    with: StakeholderMappingEngagementChartData = [[], [], [], [], [], []];
    without: StakeholderMappingEngagementChartData = [[], [], [], [], [], []];
}

export class StakeholderMappingEngagementWidget {
    readonly influenceChart: Chart;
    readonly interestChart: Chart;
    readonly impactChart: Chart;

    isLoadingMapping = false;
    isLoadingActivity = false;

    onDataUpdated: () => void;

    readonly colours = this.createColours([
        [183, 192, 199], // Grey      - Not Set
        [214, 238, 245], // Pale Blue - Very Low
        [174, 221, 235], //           - Low
        [134, 205, 225], //           - Moderate
        [94, 188, 215],  //           - High
        [54, 172, 206],  // Dark Blue - Very High
    ]);

    readonly labels = ['Not Set', 'Very Low', 'Low', 'Moderate', 'High', 'Very High'];

    private readonly chartConfig: ChartConfiguration = {
        type: 'bar',
        data: {
            labels: this.labels,
            datasets: [
                {
                    label: 'Engagement',
                    data: [],
                    stack: '1',
                    backgroundColor: this.colours.with.backgroundColor,
                    borderColor: this.colours.with.borderColor,
                },
                {
                    label: 'No Engagement',
                    data: [],
                    stack: '1',
                    backgroundColor: this.colours.without.backgroundColor,
                    borderColor: this.colours.without.borderColor,
                }
            ],
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    ticks: {
                        display: false,
                    }
                }],
                yAxes: [{
                    stacked: true,
                }],
            },
            legend: {
                display: false,
            },
            tooltips: {
                mode: 'index',
                callbacks: {
                    label: (tooltipItem, data) => {
                        const label = data.datasets[tooltipItem.datasetIndex].label;
                        const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] as number;

                        const engagementValue = data.datasets[0].data[tooltipItem.index] as number;
                        const noEngagementValue = data.datasets[1].data[tooltipItem.index] as number;
                        const totalValue = engagementValue + noEngagementValue;

                        let percent = '0.00';
                        if (totalValue > 0) {
                            percent = (value * 100.0 / totalValue).toFixed(2);
                        }

                        return `${label}: ${value} (${percent}%)`;
                    }
                }
            },
        },
    };

    private _mappingData: StakeholderModelMapping[];
    private _activityData: number[];

    private _influence: StakeholderMappingEngagementChartFullData;
    private _interest: StakeholderMappingEngagementChartFullData;
    private _impact: StakeholderMappingEngagementChartFullData;

    constructor(
        influenceChart: JQuery<HTMLCanvasElement>,
        interestChart: JQuery<HTMLCanvasElement>,
        impactChart: JQuery<HTMLCanvasElement>,
        customChartConfig?: ChartConfiguration,
    ) {
        this.influenceChart = new Chart(influenceChart, $.extend(true, {}, this.chartConfig, customChartConfig));
        this.interestChart = new Chart(interestChart, $.extend(true, {}, this.chartConfig, customChartConfig));
        this.impactChart = new Chart(impactChart, $.extend(true, {}, this.chartConfig, customChartConfig));
    }

    public get mappingData(): StakeholderModelMapping[] {
        return this._mappingData;
    }

    public set mappingData(value: StakeholderModelMapping[]) {
        this._mappingData = value;
        this.updateData();
    }

    public get activityData(): number[] {
        return this._activityData;
    }

    public set activityData(value: number[]) {
        this._activityData = value;
        this.updateData();
    }

    public get influence(): StakeholderMappingEngagementChartFullData {
        return this._influence;
    }

    public get interest(): StakeholderMappingEngagementChartFullData {
        return this._interest;
    }

    public get impact(): StakeholderMappingEngagementChartFullData {
        return this._impact;
    }

    public drawLegendIcons(engagementLegendIcons: JQuery<HTMLCanvasElement>, mappingLegendIcons: JQuery<HTMLCanvasElement>): void {
        const colours = this.colours;
        const size = 12;

        engagementLegendIcons.each(function () {
            const key = $(this).data('key');
            const colour = colours[key].backgroundColor[0];

            DrawLegendIcon(this, colour, size);
        });

        mappingLegendIcons.each(function () {
            const index = $(this).data('index');
            const colour = colours.with.borderColor[index];

            DrawLegendIcon(this, colour, size);
        });
    }

    private updateData(): void {
        // Do nothing if either data set is still loading
        if (this.isLoadingMapping || this.isLoadingActivity) {
            return;
        }

        if (!this.mappingData) {
            this.setChartData(this.influenceChart, [], []);
            this.setChartData(this.interestChart, [], []);
            this.setChartData(this.impactChart, [], []);
            return;
        }

        // Create a Set of the Stakeholder IDs with activity in the current date range
        const stakeholderIDsWithActivity = new Set();
        if (this.activityData) {
            this.activityData.forEach(function (id) {
                stakeholderIDsWithActivity.add(id);
            });
        }

        // Calculate the number of Stakeholders at each level of Influence, Interest and Impact
        // with and without activity in the current date range
        // [0] is Not Set, [1] is Very Low, [5] is Very High
        const influence = new StakeholderMappingEngagementChartFullData();
        const interest = new StakeholderMappingEngagementChartFullData();
        const impact = new StakeholderMappingEngagementChartFullData();

        this._influence = influence;
        this._interest = interest;
        this._impact = impact;

        const getIndex = (value) => value > 0 && value <= 5 ? value : 0;

        this.mappingData.forEach((stakeholder) => {
            const hasActivity = stakeholderIDsWithActivity.has(stakeholder.StakeholderID);

            const influenceArray = hasActivity ? influence.with : influence.without;
            influenceArray[getIndex(stakeholder.Influence)].push(stakeholder.StakeholderID);

            const interestArray = hasActivity ? interest.with : interest.without;
            interestArray[getIndex(stakeholder.Interest)].push(stakeholder.StakeholderID);

            const impactArray = hasActivity ? impact.with : impact.without;
            impactArray[getIndex(stakeholder.Impact)].push(stakeholder.StakeholderID);
        });

        // Update the with and without datasets with the activity counts for the Influence, Interest and Impact charts

        this.setChartData(this.influenceChart, influence.with, influence.without);
        this.setChartData(this.interestChart, interest.with, interest.without);
        this.setChartData(this.impactChart, impact.with, impact.without);

        if (this.onDataUpdated) {
            this.onDataUpdated();
        }
    }

    private setChartData(
        chart: Chart,
        withData: StakeholderMappingEngagementChartData | [],
        withoutData: StakeholderMappingEngagementChartData | []
    ): void {
        chart.data.datasets[0].data = withData.map(d => d.length);
        chart.data.datasets[1].data = withoutData.map(d => d.length);

        const $canvas = $(chart.canvas);
        const $noData = $canvas.siblings('.no-data');

        if (withData.length === 0 && withoutData.length === 0) {
            $canvas.hide();
            $noData.show();
        } else {
            $canvas.show();
            $noData.hide();
        }

        chart.update();
    }


    // Colour functions adapted from ng2-charts
    private rgba(colour: RGB, alpha: number) {
        return 'rgba(' + colour.concat(alpha).join(',') + ')';
    }

    private createPattern(patternKey: ShapeType, colour: RGB) {
        const patt = pattern.draw(patternKey, this.rgba(colour, 1));
        return patt;
    }

    private createColours(colours: RGB[]) {
        return {
            with: // With Activity
            {
                backgroundColor: colours.map((colour) => this.createPattern('dash', colour)),
                borderColor: colours.map((colour) => this.rgba(colour, 1)),
            },
            without: // Without Activity
            {
                backgroundColor: colours.map((colour) => this.rgba(colour, 1)),
                borderColor: colours.map((colour) => this.rgba(colour, 1)),
            },
        };
    }
}

