import { RGB } from './models';

/**
 * Draws a circular legend icon on a canvas using the specified colour and size.
 *
 * @param canvasElement The canvas on which to draw the icon.
 * @param fill The colour, gradient or pattern to fill the icon with.
 * @param size The width and height of the canvas. This will also be the diameter of the icon.
 */
export function DrawLegendIcon(canvasElement: HTMLCanvasElement, fill: string | CanvasGradient | CanvasPattern, size: number): void {
    const ctx = canvasElement.getContext('2d');
    ctx.save();

    // Clear the canvas
    ctx.clearRect(0, 0, size, size);

    // Create a circular clipping path
    ctx.beginPath();
    ctx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2, true);
    ctx.clip();

    // Draw the background
    ctx.fillStyle = fill;
    ctx.fillRect(0, 0, size, size);

    ctx.restore();
}

export function rgba(colour: RGB, alpha) {
    return 'rgba(' + colour.concat(alpha).join(',') + ')';
}
