import Chart, { ChartConfiguration, ChartData, ChartDataSets } from 'chart.js';
import 'chartjs-plugin-stacked100';
import { InteractionSentimentData, RGB, Stacked100Chart } from './models';
import { DrawLegendIcon, rgba } from './shared';

type InteractionSentimentChartData = [number, number, number, number, number];

type InteractionSentimentChartFullData = [
    InteractionSentimentChartData,
    InteractionSentimentChartData,
    InteractionSentimentChartData,
    InteractionSentimentChartData,
    InteractionSentimentChartData,
]

export class InteractionSentimentByMethodWidget {
    readonly chart: Chart;

    isLoadingSentiment = false;

    private readonly colours = this.createColours([
        [214, 238, 245], //  Negative
        [174, 221, 235], //  Somewhat Negative
        [134, 205, 225], //  Moderate
        [94, 188, 215],  //  Somewhat Positive
        [54, 172, 206],  //  Positive
    ]);

    private readonly chartConfig: Stacked100Chart.Configuration = {
        type: 'horizontalBar',
        options: {
            responsive: true,
            legend: {
                display: false,
            },
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    ticks: {
                        callback: value => value + '%',
                    },
                    stacked: true,
                }],
            },
            tooltips: {
                callbacks: {
                    // Adapted from chartjs-plugin-stacked100 documentation
                    label: (tooltipItem, data) => {
                        const datasetIndex = tooltipItem.datasetIndex;
                        const datasetLabel = data.datasets[datasetIndex].label;
                        // You can use two type values.
                        // `data.originalData` is raw values,
                        // `data.calculatedData` is percentage values, e.g. 20.5 (The total value is 100.0)
                        const originalValue = data.originalData[datasetIndex][tooltipItem.index];
                        const rateValue = data.calculatedData[datasetIndex][tooltipItem.index];
                        return `${datasetLabel}: ${originalValue} (${rateValue}%)`;
                    },
                },
            },
            plugins: {
                stacked100: {
                    enable: true,
                    replaceTooltipLabel: false,
                },
            },
        },
    };

    private readonly sentimentLabels = [
        'Negative',
        'Somewhat Negative',
        'Neutral',
        'Somewhat Positive',
        'Positive',
    ];

    private readonly sentimentTypes = [
        'Negative',
        'Somewhat Negative',
        'Neutral',
        'Somewhat Positive',
        'Positive',
    ];

    private readonly sentimentTypeToIndex = new Map(
        this.sentimentTypes.map((label, index) => [label, index])
    );

    private _sentimentData: InteractionSentimentData;

    constructor(
        chart: JQuery<HTMLCanvasElement>,
        customChartConfig?: ChartConfiguration,
    ) {
        this.chart = new Chart(
            chart,
            $.extend(true, {}, this.chartConfig, customChartConfig)
        );
    }

    public get sentimentData(): InteractionSentimentData {
        return this._sentimentData;
    }

    public set sentimentData(value: InteractionSentimentData) {
        this._sentimentData = value;
        this.updateData();
    }

    public drawLegendIcons(sentimentLegendIcons: JQuery<HTMLCanvasElement>): void {
        const sentimentLabels = this.sentimentLabels;
        const colours = this.colours;
        const size = 12;

        sentimentLegendIcons.each(function () {
            const index = $(this).data('index');

            $(this).siblings('.legend-item-label').text(sentimentLabels[index]);

            const colour = colours[index].backgroundColor;
            DrawLegendIcon(this, colour, size);
        });
    }

    private updateData() {
        // Do nothing if the data is still loading
        if (this.isLoadingSentiment) {
            return;
        }

        if (!this.sentimentData || this.sentimentData.Interactions.length === 0) {
            this.setChartData(this.chart, { labels: [], datasets: [] });
            return;
        }

        // Count the number of Interactions in each Method and Sentiment Category
        const methodSentimentCountsMap = new Map<string, InteractionSentimentChartData>();
        this.sentimentData.Interactions.forEach(interaction => {
            if (interaction.SentimentType && typeof interaction.SentimentScore === 'number') {
                let sentimentCounts = methodSentimentCountsMap.get(interaction.MethodName);
                if (!sentimentCounts) {
                    sentimentCounts = [0, 0, 0, 0, 0];
                    methodSentimentCountsMap.set(interaction.MethodName, sentimentCounts);
                }

                const index = this.sentimentTypeToIndex.get(interaction.SentimentType);
                sentimentCounts[index]++;
            }
        });

        // Sort the Method and Sentiment Count pairs by their total number of Interactions in ascending order
        const sortedMethodSentimentCounts = [...methodSentimentCountsMap.entries()].map(pair => {
            const method = pair[0];
            const sentimentCounts = pair[1];

            return {
                method: method,
                sentimentCounts: sentimentCounts,
                total: sentimentCounts.reduce((accumulator, currentValue) => accumulator + currentValue),
            };
        }).sort((a, b) => a.total - b.total);

        // Get the top 5
        const top5MethodSentimentCounts = sortedMethodSentimentCounts.slice(-5);

        // Transform them into a dataset for each Sentiment Category
        const sentimentMethodCounts: InteractionSentimentChartFullData = [
            [0, 0, 0, 0, 0], // Negative
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0], // Positive
        ];

        top5MethodSentimentCounts.forEach((methodSentimentCounts, methodIndex) => {
            const sentimentCounts = methodSentimentCounts.sentimentCounts;

            sentimentCounts.forEach((count, sentimentIndex) => {
                const methodCounts = sentimentMethodCounts[sentimentIndex];
                methodCounts[methodIndex] = count;
            });
        });

        // Create a Chart.js dataset for each Sentiment Category
        const datasets = sentimentMethodCounts.map((sentimentMethodCount, sentimentIndex): ChartDataSets => {
            return {
                label: this.sentimentLabels[sentimentIndex],
                data: sentimentMethodCount,
                backgroundColor: this.colours[sentimentIndex].backgroundColor,
                borderColor: this.colours[sentimentIndex].borderColor,
                stack: '1',
                categoryPercentage: 1.0,
                barPercentage: 1.0,
            };
        });

        // Create the array of method labels for Chart.js
        const methodLabels = top5MethodSentimentCounts.map(methodSentimentCounts => methodSentimentCounts.method);

        const dataSource: ChartData = {
            labels: methodLabels,
            datasets: datasets
        };

        this.setChartData(this.chart, dataSource);
    }

    private setChartData(chart: Chart, data: ChartData) {
        chart.data = data;

        const $canvas = $(chart.canvas);
        const $noData = $canvas.siblings('.no-data');

        if (data.datasets === undefined || data.datasets === null || data.datasets.length === 0) {
            $canvas.hide();
            $noData.show();
        } else {
            $canvas.show();
            $noData.hide();
        }

        chart.update();
    }

    private createColours(colours: RGB[]) {
        return colours.map(colour => {
            return {
                backgroundColor: rgba(colour, 1),
                borderColor: rgba(colour, 1),
            };
        });
    }
}